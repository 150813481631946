.contentWrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  padding: 40px 12px;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
