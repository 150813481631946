.bookDetail {
  & > .content > .content {
    margin-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f4f4f4;
  }

  .content {
    margin-left: 24px;
  }

  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
