.appLayout {
  .title {
    cursor: pointer;
  }
  .link {
    color: white;
    background-color: rgba(0, 0, 0, 0.186);
    padding: 10px 20px;
  }

  .content {
    flex-grow: 1;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
  }
}
