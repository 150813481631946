.Auth {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 112px 0 24px;
    min-height: 100vh;
    height: 100%;
}

.form {
    width: 96%;
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
}
