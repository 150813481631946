@import "./reset";

html {
  overflow-y: scroll;
  font-size: 100%;
}

.site-layout {
  min-height: 100vh;
}

.site-layout-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 24px;
  margin-top: 24px;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
